import './Cookies.css'

const Cookies = () => {
  return (
    <div className="legal-content" id="cookies">
      <h1>Política de Cookies</h1>
      <h3>Última actualización: 10 de octubre de 2024.</h3>
      <p>
        Esta Política de Cookies es parte integrante de las Condiciones de Uso y
        la Política de Privacidad de la página web de TrueFlag.ai, (en adelante,
        el &quot;Sitio Web&quot; o la &quot;Página Web&quot;). El acceso y la
        navegación en el Sitio Web, o el uso de los servicios del mismo,
        implican la aceptación de los términos y condiciones recogidos en las
        Condiciones de Uso y en la Política de Privacidad.
      </p>

      <p>
        Con el fin de facilitar su navegación por el Sitio Web, INAR. IA LABS &
        TECHNOLOGIES SL (en adelante, &quot;Inar.ia&quot;) con domicilio en
        Calle Claudio Coello, 77, 4º planta, 28001, Madrid, con CIF B70633235,
        le comunica que utiliza cookies u otros archivos de funcionalidad
        similar (en adelante, las &quot;Cookies&quot;).
      </p>

      <p>
        En este sentido y con el objetivo de garantizar al usuario toda la
        información necesaria para su correcta navegación, ponemos a disposición
        del usuario el siguiente texto informativo sobre qué son las Cookies,
        qué tipología de Cookies existen en nuestra Página Web y cómo es posible
        configurarlas o deshabilitarlas.
      </p>

      <h2>1. ¿Qué son las Cookies?</h2>

      <p>
        Actualmente, todas las páginas web, independientemente del servicio que
        presten, utilizan Cookies, es decir, guardan una información mínima de
        la utilización realizada por el usuario en la Página Web. Una Cookie
        puede definirse como un pequeño archivo de texto o dispositivo de uso
        generalizado que se descarga en el equipo terminal del usuario cuando
        navega por las páginas web con la finalidad de almacenar datos que
        podrán ser actualizados y recuperados por la entidad responsable de su
        instalación para mejorar la navegación y la experiencia de usuario.
      </p>

      <h2>2. Tipología de Cookies</h2>

      <p>
        Por favor, revise esta sección que proporciona una visión general de la
        tipología de Cookies que se pueden utilizar en un entorno online.
      </p>

      <p>En función del propietario de las Cookies, pueden clasificarse en:</p>

      <ul>
        <li>
          Cookies propias: Son aquellas que se envían al ordenador o dispositivo
          del usuario desde un equipo o dominio gestionado por el propio editor,
          y desde el que se ofrece la plataforma o el servicio solicitado por el
          usuario.
        </li>
        <li>
          Cookies de tercero: Son aquellas que se envían al ordenador o
          dispositivo del usuario desde un equipo o dominio que no es gestionado
          por el editor, sino por otra entidad que trata los datos obtenidos a
          través de las Cookies.
        </li>
      </ul>

      <p>En función de la finalidad de la Cookie, pueden clasificarse en:</p>

      <ul>
        <li>
          Cookies estrictamente necesarias (técnicas): Son aquellas que permiten
          al usuario la navegación a través de una página web, plataforma o
          aplicación y la utilización de las diferentes opciones o servicios que
          en ellas existan como, por ejemplo, controlar el tráfico, identificar
          datos o la sesión, acceder a secciones o contenidos de acceso
          restringido, recordar los elementos que integran un pedido, realizar
          el proceso de compra de un pedido, gestionar el pago, controlar el
          fraude vinculador a la seguridad del servicio, utilizar elementos de
          seguridad durante la navegación, realizar la solicitud de inscripción
          o participación en un evento, almacenar contenidos para la difusión de
          videos o sonido, habilitar contenidos dinámicos (por ejemplo,
          animación de carga de un texto o imagen&) o compartir contenidos a
          través de redes sociales. Las Cookies técnicas, al ser estrictamente
          necesarias, se descargan por defecto cuando permitan mostrar la
          plataforma o prestar el servicio solicitado por el usuario.
        </li>
        <li>
          Cookies de funcionalidad o personalización: Estas Cookies son aquellas
          que permiten recordar información para que el usuario acceda al
          servicio o plataforma con determinadas características que pueden
          diferenciar su experiencia de la de otros usuarios, como, por ejemplo,
          el idioma, el número de resultados a mostrar cuando el usuario realiza
          una búsqueda, el aspecto o contenido del servicio en función del tipo
          de navegador usado por el usuario o de la región desde la que accede
          al servicio, etc. El no aceptar estas Cookies puede generar un
          rendimiento lento de la página web o recomendaciones mal adaptadas.
        </li>
        <li>
          Cookies de análisis: Son aquellas que permiten cuantificar el número
          de usuarios, las secciones visitadas en la plataforma y cómo
          interactúan con la misma, para así realizar la medición y análisis
          estadístico de la utilización que hacen los usuarios, con el fin de
          introducir mejoras en función del análisis de los datos de uso que
          hacen los usuarios de la plataforma o servicio.
        </li>
        <li>
          Cookies de publicidad comportamental: Son aquellas que almacenan
          información del comportamiento de los usuarios obtenida a través de la
          observación continuada de sus hábitos de navegación, lo que permite
          desarrollar un perfil específico para mostrar publicidad en función
          del mismo. Estas Cookies permiten la gestión, de la forma más eficaz
          posible, de los espacios publicitarios que, en su caso, el editor haya
          incluido directamente o en colaboración con terceros.
        </li>
      </ul>

      <h2>3. Cuadro resumen de Cookies utilizadas en el Sitio Web</h2>

      <table border={1} cellPadding={10} cellSpacing={0}>
        <thead>
          <tr>
            <th>COOKIES</th>
            <th>TIPO</th>
            <th>FINALIDAD</th>
            <th>DURACIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>didomi_token</td>
            <td>Cookie</td>
            <td>
              This cookie contains consent information for custom purposes and
              vendors, as well as Didomi-specific information (user ID, for
              instance).
            </td>
            <td>12 months</td>
          </tr>
          <tr>
            <td>didomi_token</td>
            <td>Web storage</td>
            <td>
              This cookie contains consent information for custom purposes and
              vendors, as well as Didomi-specific information (user ID, for
              instance).
            </td>
            <td>session / unknown</td>
          </tr>
          <tr>
            <td>euconsent</td>
            <td>Cookie</td>
            <td>
              Contains the IAB TCF consent string and consent information for
              all the standard IAB vendors and purposes.
            </td>
            <td>session / unknown</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Cookie</td>
            <td>Used to distinguish users.</td>
            <td>24 months</td>
          </tr>
          <tr>
            <td>_ga_#</td>
            <td>Cookie</td>
            <td>Used to persist session state.</td>
            <td>24 months</td>
          </tr>
        </tbody>
      </table>

      <h2>4. Procedimiento de oposición a la instalación de las Cookies</h2>

      <p>
        La mayoría de ordenadores aceptan la utilización de Cookies
        automáticamente. Sin embargo, el usuario tiene la posibilidad de decidir
        si mantiene las Cookies habilitadas o no en su ordenador. El hecho de
        deshabilitar las Cookies puede implicar la pérdida de un funcionamiento
        óptimo de la página Web quedando inhabilitadas algunas de las
        características o servicios prestados por la misma. Sin embargo, incluso
        habiendo desactivado todas las Cookies el navegador Web recoge
        determinada información esencial para el funcionamiento básico de la
        Página Web.
      </p>

      <p>
        El usuario que quiera deshabilitar las Cookies puede hacerlo desde la
        sección de preferencias de su navegador de Internet. Dependiendo del
        navegador deberá de seguir determinados pasos para modificar la
        configuración de las Cookies en su ordenador:
      </p>

      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=es">
            Chrome
          </a>
        </li>
        <li>
          <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES&locale=es_ES">
            Safari
          </a>
        </li>
        <li>
          <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we#w_lcahmo-cambio-la-configuraciahn-de-las-cookies">
            Firefox
          </a>
        </li>
        <li>
          <a href="http://windows.microsoft.com/es-419/windows7/how-to-manage-cookies-in-internet-explorer-9">
            Internet Explorer
          </a>
        </li>
      </ul>

      <p>
        Esta posibilidad de evitar el uso de Cookies puede ser llevada a cabo en
        cualquier momento.
      </p>

      <p>
        Por favor, tenga en cuenta que opción de rechazar las Cookies es
        específica para cada navegador que esté utilizando. Por lo tanto, si
        opta por configurar las Cookies de una forma en un dispositivo y desea
        que su opción se aplique de manera similar a otro dispositivo, deberá
        activar la misma opción para ese otro dispositivo.
      </p>
    </div>
  )
}

export default Cookies
