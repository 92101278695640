import social from 'src/data/social.json'

import './SocialLinks.css'

const SocialLinks = () => {
  return (
    <div className="social-links">
      {social.map((c) => (
        <a
          key={c.name}
          target="_blank"
          rel="noreferrer noopener"
          className={`icon icon-${c.name}`}
          href={c.href}
          title={`${c.name} link`}
        />
      ))}
    </div>
  )
}

export default SocialLinks
