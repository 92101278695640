import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const rootElement = document.querySelector('.page')
    if (rootElement) {
      rootElement.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

export default ScrollToTop
