import './Terms.css'

const Terms = () => {
  return (
    <div className="legal-content" id="terms">
      <h1>Condiciones de uso</h1>
      <h3>Última actualización: 10 de octubre de 2024.</h3>
      <h2>Responsable del sitio web</h2>
      <p>
        INAR. IA LABS & TECHNOLOGIES SL, sociedad inscrita en el Registro
        Mercantil de Madrid, en el Tomo 46413, Folio 80, Sección 8, Hoja
        M815141, Inscripción 1, con fecha 29 de enero de 2024, cuyo C.I.F. es el
        número B70633235 y con domicilio social en Calle Claudio Coello, 77, 4º
        planta, 28001, Madrid (en adelante, &quot;INAR.IA&quot;).
      </p>

      <h2>Uso y acceso de usuarios</h2>
      <p>
        La utilización del sitio web atribuye la condición de usuario e implica
        la aceptación plena y sin reservas de todas y cada una de las
        disposiciones incluidas en este Aviso Legal en el momento mismo en que
        el usuario acceda.
      </p>
      <p>
        INAR.IA se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en el Sitio web, pudiendo
        cambiar, suprimir o añadir tanto los contenidos y servicios que se
        presten a través del mismo como la forma en la que éstos aparezcan
        presentados o localizados en sus servidores.
      </p>

      <h2>Obligaciones del usuario</h2>
      <p>
        El Usuario se obliga, con carácter general, a utilizar el Sitio web así
        como los contenidos, servicios e información vinculados al mismo de
        forma diligente, de conformidad con la ley, la moral, el orden público y
        lo dispuesto en este Sitio web debiendo, asimismo, abstenerse de
        utilizarlos en cualquier forma que pueda impedir el normal
        funcionamiento y disfrute por parte de los usuarios del Sitio web de los
        contenidos del mismo, o que pudiera lesionar o causar daños a los bienes
        y derechos de INAR.IA, de sus proveedores, usuarios o, en general, de
        cualquier tercero.
      </p>
      <p>
        En particular, y con carácter enunciativo y no limitativo, el usuario se
        obliga en la utilización del Sitio web y de los servicios vinculados al
        mismo, a:
      </p>
      <ul>
        <li>
          No introducir, almacenar o difundir mediante el sitio web o a través
          de cualquiera de los servicios vinculados al mismo ningún programa de
          ordenador, dato, virus, código, o cualquier otro instrumento o
          dispositivo electrónico que sea susceptible de causar daños en el
          sitio web, en cualquiera de los servicios vinculados al mismo o en
          cualesquiera equipos, sistemas o redes de INAR.IA de cualquier
          usuario, de sus proveedores o en general de cualquier tercero, o que
          de otra forma sea capaz de causarles cualquier tipo de alteración o
          impedir el normal funcionamiento del mismo
        </li>
        <li>
          No utilizar identidades falsas, ni suplantar la identidad de otros en
          la utilización del Sitio web o de cualquiera de los servicios
          vinculados al mismo, incluyendo la utilización en su caso de
          contraseñas o claves de acceso de terceros o de cualquier otra forma.
        </li>
        <li>
          No destruir, alterar, inutilizar o dañar los datos, informaciones,
          programas o documentos electrónicos de INAR.IA de sus proveedores o de
          terceros.
        </li>
        <li>
          No utilizar los contenidos y, en particular, la información obtenida a
          través del Sitio web para remitir publicidad, enviar mensajes con
          fines comerciales, ni para recoger o almacenar datos personales de
          terceros.
        </li>
      </ul>

      <h2>Contenido del Sitio web</h2>
      <p>
        INAR.IA no se responsabiliza del mal uso que se realice de los
        contenidos del Sitio web, siendo exclusiva responsabilidad del Usuario.
      </p>
      <p>
        Los contenidos del Sitio web, en especial las referencias informativas y
        publicitarias, salvo que expresamente se indique lo contrario, no
        constituyen oferta vinculante.
      </p>
      <p>
        INAR.IA se reserva el derecho a introducir modificaciones u omitir
        parcial o totalmente los actuales contenidos del Sitio web cuando lo
        considere oportuno, así como impedir o restringir el acceso de forma
        temporal o permanente.
      </p>

      <h2>Enlaces (&quot;links&quot;)</h2>
      <p>
        La presencia de enlaces (links) en el Sitio web tiene una finalidad
        meramente informativa y en ningún caso supone sugerencia, invitación o
        recomendación sobre los mismos.
      </p>
      <p>
        INAR.IA no garantiza la disponibilidad técnica, calidad, fiabilidad,
        exactitud o veracidad de los contenidos y servicios disponibles en
        sitios pertenecientes o gestionados por terceros a los que el usuario
        pueda acceder mediante dispositivos técnicos de enlace
        (&quot;links&quot;) desde el Sitio web.
      </p>
      <p>
        INAR.IA no controla el contenido de dichos sitios web ni ofrece ni
        comercializa los productos y servicios disponibles en los sitios web así
        enlazados, ni asume en consecuencia responsabilidad alguna por los
        mismos.
      </p>

      <h2>Propiedad intelectual e industrial</h2>
      <p>
        El usuario reconoce que todos los elementos del Sitio web, la
        información y materiales contenidos en el mismo, la estructura,
        selección, ordenación y presentación de sus contenidos y los programas
        de ordenador utilizados en relación con el mismo están protegidos por
        derechos de propiedad intelectual e industrial de la propia INAR.IA.
      </p>
      <p>
        Salvo que fuera autorizado por INAR.IA o, en su caso, por los terceros
        titulares de los derechos correspondientes, o a menos que ello resulte
        legalmente permitido, el usuario no podrá reproducir, transformar,
        modificar, desensamblar, realizar ingeniería inversa, distribuir,
        alquilar, prestar, poner a disposición o permitir el acceso al público a
        través de cualquier modalidad de comunicación pública de ninguno de los
        elementos anteriores.
      </p>
      <p>
        El usuario deberá emplear los materiales, elementos e información a la
        que acceda a través de la utilización del Sitio web únicamente para sus
        propias necesidades, obligándose a no realizar ni directa ni
        indirectamente una explotación comercial ni de los servicios ni de los
        materiales, elementos e información obtenidos a través de los mismos.
      </p>
      <p>
        El usuario deberá abstenerse de suprimir los signos identificativos de
        los derechos (de propiedad intelectual, industrial o cualquier otro) de
        INAR.IA o de los terceros que figuren en el Sitio web y en los
        contenidos ofrecidos a través de él.
      </p>
      <p>
        Asimismo, el usuario deberá abstenerse de eludir o manipular
        cualesquiera dispositivos técnicos establecidos por INAR.IA o por
        terceros, ya sea en el Sitio web, en cualquiera de los materiales,
        elementos o información obtenidos a través del mismo, para la protección
        de sus derechos.
      </p>

      <h2>Política de privacidad</h2>
      <p>
        Es compromiso de INAR.IA el respeto de la privacidad de los usuarios y
        la protección y seguridad de sus datos personales. Por ello, puedes
        acceder a nuestra política de privacidad completa a través del siguiente
        enlace{' '}
        <a href="https://trueflag.ai/legal/privacy-policy">
          https://trueflag.ai/legal/privacy-policy
        </a>
        .
      </p>

      <h2>Contraseñas</h2>
      <p>
        INAR.IA facilitará el uso de contraseñas personales a sus clientes para
        el acceso al área de clientes. El Usuario deberá mantener bajo su
        exclusiva responsabilidad las contraseñas en la más estricta y absoluta
        confidencialidad, asumiendo, por tanto, cuantos daños o consecuencias de
        todo tipo se deriven del quebrantamiento o revelación del secreto. Por
        razones de seguridad, la contraseña de acceso telemático al área de
        clientes podrá ser modificada por el Usuario.
      </p>

      <h2>Cookies</h2>
      <p>
        La óptima funcionalidad del Portal Web de INAR.IA se basa en la
        utilización de cookies. Por ello, puedes acceder a nuestra política
        completa de cookies a través del siguiente enlace{' '}
        <a href="https://trueflag.ai/cookie-preferences">
          https://trueflag.ai/legal/cookie-preferences
        </a>
        .
      </p>

      <h2>Legislación</h2>
      <p>
        Con carácter general las relaciones entre INAR.IA con los usuarios de
        sus servicios telemáticos, presentes en el Sitio web, se encuentran
        sometidas a la legislación y jurisdicción española.
      </p>
      <p>
        Para cualquier cuestión litigiosa ambas partes, INAR.IA y el Usuario,
        con renuncia expresa a cualquier otro fuero que pudiera corresponderles,
        se someten a la jurisdicción y competencia exclusiva de los Juzgados y
        Tribunales de Madrid.
      </p>

      <h2>Exclusión de Garantías</h2>
      <p>
        INAR.IA se compromete a llevar a cabo todos los esfuerzos necesarios
        para garantizar la disponibilidad y continuidad del Sitio web, así como
        de los servicios vinculados al mismo. No obstante, INAR.IA no puede
        garantizar que el Sitio web y los servicios vinculados al mismo
        funcionen correctamente en todo momento, que el usuario pueda acceder a
        ellos y utilizarlos de forma rápida, ininterrumpida y libre de errores.
      </p>
      <p>
        De la misma forma, INAR.IA no otorga ninguna garantía respecto a la
        idoneidad y contenido del Sitio web o de cualquiera de los servicios
        vinculados al mismo para la satisfacción de las necesidades específicas
        del Usuario.
      </p>
      <p>
        INAR.IA actuará diligentemente según los usos generales aceptados en el
        sector para evitar la presencia en el Sitio web o en los servicios
        vinculados al mismo de virus u otros elementos lesivos que pudieran
        causar alteraciones en el sistema informático del usuario, en sus
        documentos electrónicos o en sus ficheros, pero no puede garantizar la
        ausencia de tales elementos, no siendo responsable por los daños y
        perjuicios que ello pudiera ocasionar.
      </p>
      <p>
        INAR.IA no controla, ni hace propios, ni garantiza la exactitud,
        calidad, veracidad, fiabilidad o idoneidad de las informaciones y
        servicios facilitados o prestados por terceros a través del Sitio web.
        De igual forma, no controla y no garantiza la ausencia de virus u otros
        elementos lesivos en los contenidos o servicios facilitados o prestados
        por terceros a través del Sitio web.
      </p>

      <h2>Resolución</h2>
      <p>
        Sin perjuicio de la responsabilidad por daños y perjuicios que se
        pudiera derivar, INAR.IA podrá, con carácter inmediato y sin necesidad
        de preaviso, resolver y dar por terminada su relación con el usuario,
        interrumpiendo su acceso al Sitio web o a sus correspondientes
        servicios, si detecta un uso de los mismos o de cualquiera de los
        servicios vinculados al mismo que sea contrario a este Aviso Legal.
      </p>
    </div>
  )
}

export default Terms
