import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const Navigation = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to={'/'}>
            <FormattedMessage id="home" />
          </NavLink>
        </li>
        <li>
          <NavLink to={'/'}>
            <FormattedMessage id="team" />
          </NavLink>
        </li>
        <li>
          <NavLink to={'/'}>
            <FormattedMessage id="about" />
          </NavLink>
        </li>
        <li>
          <NavLink to={'/#contact'}>
            <FormattedMessage id="contact" />
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
