import './Privacy.css'

const Privacy = () => {
  return (
    <div className="legal-content" id="privacy">
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <h3>Última actualización: 10 de octubre de 2024.</h3>
      <h2>1&#41; RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES</h2>
      <p>
        El responsable del tratamiento es INAR. IA LABS & TECHNOLOGIES SL, con
        CIF B70633235, y domicilio social en Calle Claudio Coello, 77, 4º
        planta, 28001, Madrid (en adelante, &ldquo;INAR.IA&ldquo;).
      </p>
      <p>
        Esta Política de Privacidad (junto con las Condiciones de Uso y otros
        documentos referenciados en las mismas), establece las bases sobre las
        que INAR.IA trata su información a través de la página web trueflag.ai,
        titularidad de INAR.IA] (en adelante, el &ldquo;Sitio Web&ldquo;).
      </p>
      <p>
        Cada vez que use el Sitio Web y nos facilite o sea necesario que
        accedamos a cualquier tipo de información que por sus características
        nos permita identificarle (en adelante, Datos Personales), ya sea para
        navegar por la misma y hacer uso de sus servicios o funcionalidades
        (registrarse como usuario, inscribirse como candidato a una oferta,
        enviar una consulta a través del formulario de contacto, etc.), estará
        bajo la aplicación de la Política de Privacidad vigente en cada momento,
        debiendo revisar dicho texto para comprobar que está conforme con él.
      </p>

      <h2>2&#41; INFORMACIÓN Y CONSENTIMIENTO</h2>
      <p>
        El Usuario queda informado y presta su consentimiento libre, informado,
        específico e inequívoco para que los Datos Personales que facilite a
        través del Sitio Web, así como los datos derivados de su navegación y
        aquellos otros datos que pueda facilitar en un futuro a la entidad, sean
        tratados por INAR.IA, de conformidad con lo descrito en la presente
        política.
      </p>

      <h2>3&#41; OBLIGATORIEDAD DE FACILITAR LOS DATOS</h2>
      <p>
        Los datos solicitados en los formularios dispuestos en el Sitio Web son,
        con carácter general, obligatorios (salvo que en el campo requerido se
        especifique lo contrario) para cumplir con las finalidades establecidas.
      </p>
      <p>
        Por lo tanto, si no se facilitan los mismos o no se facilitan
        correctamente, no podrán atenderse las solicitudes de los usuarios.
      </p>

      <h2>4&#41; ¿CON QUÉ FINALIDAD TRATAREMOS SUS DATOS?</h2>
      <p>
        Los Datos Personales facilitados a través del Sitio Web serán tratados
        por INAR.IA con las finalidades descritas a continuación, y en todo
        caso, para responder a las solicitudes que pueda efectuar:
      </p>
      <ul>
        <li>
          Ofrecerle información sobre nuestras líneas de negocio, productos y
          servicios.
        </li>
        <li>
          Inscribirse en las oportunidades de empleo ofertadas por trueflag.ai
          en la presente web a través del formulario habilitado al efecto.
        </li>
        <li>Atender cuantas solicitudes pueda realizar el interesado.</li>
        <li>Gestionar el acceso y uso del área de clientes.</li>
        <li>
          Gestionar las contrataciones de productos que realice el usuario a
          través del Sitio Web.
        </li>
        <li>
          Realizar informes estadísticos anónimos respecto de los hábitos de
          acceso y la actividad desarrollada por los usuarios en el Sitio Web.
        </li>
        <li>
          Y si el usuario así lo solicita / consiente, remitirle comunicaciones
          comerciales sobre nuestros productos y servicios.
        </li>
      </ul>

      <h2>5&#41; ¿QUÉ DATOS PODRÁN SER TRATADOS POR INAR.IA?</h2>
      <ul>
        <li>Datos identificativos: nombre, apellidos, nacionalidad.</li>
        <li>
          Datos de contacto: dirección de correo electrónico, número de
          teléfono.
        </li>
        <li>
          Datos de navegación: los mínimos imprescindibles para posibilitar el
          uso del Sitio Web.
        </li>
      </ul>

      <h2>6&#41; ¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO DE TUS DATOS?</h2>
      <p>
        La legitimación del tratamiento de Datos Personales es la siguiente:
      </p>
      <ul>
        <li>
          Para la selección de personal, será el consentimiento prestado por el
          candidato,
        </li>
        <li>
          Para la gestión de consultas, solicitudes de información, quejas y
          reclamaciones, el consentimiento prestado por el usuario para ello.
        </li>
        <li>
          Para la gestión del acceso y uso del área privada de clientes, la
          relación contractual establecida.
        </li>
        <li>
          Para la realización de informes estadísticos anónimos, el interés
          legítimo de INAR.IA.
        </li>
        <li>
          Para el tratamiento de sus datos con fines de marketing, relativo a
          productos y servicios propios de INAR.IA para sus clientes, se basará
          en el interés legítimo reconocido por la normativa.
        </li>
      </ul>
      <p>
        Podrá revocar su consentimiento a cualquiera de los tratamientos, si
        bien ello no afectará a la licitud de los tratamientos efectuados con
        anterioridad.
      </p>
      <p>
        Para revocar dicho consentimiento, el Usuario podrá contactar con
        INAR.IA a través de comunicación escrita dirigida a Calle Claudio
        Coello, 77, 4º planta, 28001, Madrid, o mediante e-mail dirigido a
        contact@trueflag.ai.
      </p>

      <h2>7&#41; ¿CON QUIÉN SE COMPARTIRÁN LOS DATOS DEL USUARIO?</h2>
      <p>
        Los datos del usuario podrán ser comunicados a nuestros proveedores de
        servicios, necesarios para prestarle los servicios solicitados. Dichos
        proveedores no tratarán sus datos para finalidades propias que no hayan
        sido previamente informadas por INAR.IA.
      </p>

      <h2>8&#41; TRANSFERENCIAS INTERNACIONALES DE DATOS</h2>
      <p>
        Le informamos de que sus datos pueden ser transferidos a terceros países
        ubicados fuera del Espacio Económico Europeo, únicamente para fines
        administrativos internos y/o para las finalidades anteriormente
        indicadas.
      </p>

      <h2>
        9&#41; ¿DURANTE CUÁNTO TIEMPO CONSERVAREMOS LOS DATOS DEL USUARIO?
      </h2>
      <p>
        Los datos serán tratados durante el tiempo necesario para la realización
        de las finalidades para las que fueron recogidos, salvo que el usuario
        solicite su baja a INAR.IA, se oponga o revoque su consentimiento.
      </p>
      <p>
        Posteriormente, los datos personales facilitadas por el interesado para
        la formalización y gestión de la contratación de productos serán
        conservados durante 5 años desde su terminación, cualquiera que sea la
        causa de finalización de la misma.
      </p>
      <p>
        En el supuesto de selección de personal, sus datos curriculares serán
        conservados durante un plazo de: (..)
      </p>

      <h2>10&#41; RESPONSABILIDAD DEL USUARIO</h2>
      <p>El usuario:</p>
      <ul>
        <li>
          Garantiza que es mayor de catorce (14) años y que los datos que
          facilita a INAR.IA son verdaderos, exactos, completos y actualizados.
          A estos efectos, el usuario responde de la veracidad de todos los
          datos que comunique y mantendrá convenientemente actualizada la
          información facilitada, de tal forma que responda a su situación real.
        </li>
        <li>
          Garantiza que ha informado a los terceros de los que facilite sus
          datos, en caso de hacerlo, de los aspectos contenidos en la presente
          política. Asimismo, garantiza que ha obtenido su autorización para
          facilitar sus datos a INAR.IA para los fines señalados.
        </li>
        <li>
          Será responsable de las informaciones falsas o inexactas que
          proporcione a través del Sitio Web y de los daños y perjuicios,
          directos o indirectos, que ello cause a INAR.IA o a terceros.
        </li>
      </ul>

      <h2>11&#41; EJERCICIO DE DERECHOS</h2>
      <p>
        El usuario puede enviar un escrito a INAR.IA para ejercitar sus derechos
        en cualquier momento, de manera gratuita, adjuntando fotocopia de su
        documento de identidad, con la referencia &ldquo;Protección de
        Datos&ldquo;, a través de los siguientes medios:
      </p>
      <ul>
        <li>
          Correo postal: Calle Claudio Coello, 77, 4º planta, 28001, Madrid
        </li>
        <li>Correo electrónico: contact@trueflag.ai</li>
      </ul>
      <p>
        Le informamos de que los derechos que Usted puede ejercitar son los
        siguientes:
      </p>
      <ul>
        <li>Revocar los consentimientos otorgados.</li>
        <li>
          Obtener confirmación acerca de si en INAR.IA se están tratando datos
          personales que conciernen al usuario o no.
        </li>
        <li>Acceder a sus datos personales.</li>
        <li>Rectificar los datos inexactos o incompletos.</li>
        <li>
          Solicitar la supresión de sus datos cuando, entre otros motivos, los
          datos ya no sean necesarios para los fines que fueron recogidos.
        </li>
        <li>
          Obtener de INAR.IA la limitación del tratamiento de los datos cuando
          se cumpla alguna de las condiciones previstas en la normativa de
          protección de datos.
        </li>
        <li>
          Solicitar la portabilidad de los datos facilitados por el Usuario en
          aquellos casos previstos en la normativa.
        </li>
        <li>
          Interponer una reclamación relativa a la protección de sus datos
          personales ante la Agencia Española de Protección de Datos en la
          dirección Calle de Jorge Juan, 6, 28001 Madrid, cuando el interesado
          considere que INAR.IA ha vulnerado los derechos que le son reconocidos
          por la normativa aplicable en protección de datos.
        </li>
      </ul>

      <h2>12&#41; MEDIDAS DE SEGURIDAD</h2>
      <p>
        Trataremos sus datos en todo momento de forma absolutamente confidencial
        y guardando el preceptivo deber de secreto respecto de los mismos, de
        conformidad con lo previsto en la normativa de aplicación, adoptando al
        efecto las medidas de índole técnica y organizativas necesarias que
        garanticen la seguridad de sus datos y eviten su alteración, pérdida,
        tratamiento o acceso no autorizado, habida cuenta del estado de la
        tecnología, la naturaleza de los datos almacenados y los riesgos a que
        están expuestos.
      </p>
    </div>
  )
}

export default Privacy
