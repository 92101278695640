import { FormattedMessage } from 'react-intl'
import fallbackPosterPNG from 'src/static/images/landing/coming-soon/poster-radar.png'
import videoDesktop from 'src/static/videos/radar.mp4'
import videoMobile from 'src/static/videos/radar-mobile.mp4'

import logo from 'src/static/vectors/claim-logo.svg'
import './ComingSoon.css'

const ComingSoon = () => {
  return (
    <section className="landing-section" id="coming-soon">
      <video id="video-intro" loop autoPlay playsInline muted controls={false}>
        <img src={fallbackPosterPNG} alt="Fallback poster" />
        <source src={videoMobile} type="video/mp4" media="(max-width: 540px)" />
        <source
          src={videoDesktop}
          type="video/mp4"
          media="(min-width: 541px)"
        />
      </video>
      <div className="container">
        <img src={logo} alt="Trueflag logo" />
        <h1>
          <FormattedMessage id="home.coming-soon" />
        </h1>
      </div>
    </section>
  )
}

export default ComingSoon
