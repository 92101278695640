import { Routes, Route } from 'react-router-dom'
import Header from '@components/Header/Header'
import Footer from '@components/Footer/Footer'
import Privacy from './Privacy/Privacy'
import Terms from './Terms/Terms'
import Cookies from './Cookies/Cookies'

import './Legal.css'

const Legal = () => {
  return (
    <div id="legal" className="page">
      <Header simpleMode />
      <Routes>
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-preferences" element={<Cookies />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default Legal
