import { useRef } from 'react'
import { useScrollSelector } from '@gluedigital/scrollastic'
import Footer from '@components/Footer/Footer'
import ComingSoon from './sections/ComingSoon/ComingSoon'
// import Header from '@components/Header/Header'
// import Contact from './sections/Contact/Contact'
// import Intro from './sections/Intro/Intro'
// import ScrollDown from '@components/ScrollDown/ScrollDown'
// import Designed from './sections/Designed/Designed'
// import Verification from './sections/Verification/Verification'
// import DataFacts from './sections/DataFacts/DataFacts'

import './Landing.css'

const Landing = () => {
  const scrollable = useRef<HTMLDivElement>(null)
  const section = useScrollSelector('.landing-section', {
    scrollable,
    boundary: 0
  })
  return (
    <div id="landing" className={`page view-${section}`} ref={scrollable}>
      {/* <Header />
      <ScrollDown scrollable={scrollable} /> */}
      <ComingSoon />
      {/* <Intro scrollable={scrollable} />
      <Verification scrollable={scrollable} />
      <Designed scrollable={scrollable} />
      <DataFacts />
      <Contact /> */}
      <Footer isFixed />
    </div>
  )
}

export default Landing
