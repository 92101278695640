import Navigation from './Navigation'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SocialLinks from '@components/SocialLinks/SocialLinks'
import { NavLink } from 'react-router-dom'
import logo from 'src/static/vectors/logo.svg'
import logoW from 'src/static/vectors/logo-w.svg'

import './Header.css'

const Header = ({ simpleMode = false }: { simpleMode?: boolean }) => {
  const [open, setOpen] = useState(false)
  if (simpleMode) {
    return (
      <header id="masthead">
        <div className="container">
          <NavLink to={'/'}>
            <img src={logo} alt="Trueflag logo" />
          </NavLink>
        </div>
      </header>
    )
  }
  return (
    <>
      <header id="masthead">
        <div className="container">
          <NavLink to={'/'}>
            <img src={logo} alt="Trueflag logo" />
          </NavLink>
          <Navigation />
          <section>
            <NavLink className="button" to={'/'}>
              <FormattedMessage id="try-trueFlag" />
            </NavLink>
            <span
              className="only-responsive icon icon-plus"
              onClick={() => setOpen(!open)}
            />
          </section>
        </div>
      </header>
      <div
        id="mobile-navigation"
        className={`only-responsive ${open ? 'open' : ''}`}
      >
        <div id="mobile-navigation-content">
          <span className="icon icon-close" onClick={() => setOpen(false)} />
          <NavLink to={'/'}>
            <img src={logoW} alt="Trueflag logo" />
          </NavLink>
          <Navigation />
          <SocialLinks />
        </div>
      </div>
    </>
  )
}

export default Header
